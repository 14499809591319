import { BrowserRouter, Route, Routes } from "react-router-dom";
import Useronboard from "./Components/Useronboard";
import MainLayout from "./Layouts/MainLayout";
import PrivateRoute from "./Routes/PrivateRoute";
import PageNotFound from "./CommonComponents/PageNotFound";
import Chat from "./Components/Chat";
import ChatHistory from "./CommonComponents/ChatHistory";
import Sfcc from "./Components/Sfcc";

import { AbortControllerProvider } from "./Context/AbortControllerContext";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={
            <PrivateRoute>
              <Useronboard />
            </PrivateRoute>
            } 
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <AbortControllerProvider>
                <Chat />
                </AbortControllerProvider>

              </PrivateRoute>
            }
          />
          <Route
            path="/chatHistory"
            element={
              <PrivateRoute>
                <ChatHistory />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/sfcc" element={<Sfcc />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
