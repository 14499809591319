import React, { useState, useEffect } from 'react';
import ProductCard from '../Components/StreamingComponents/ProductCard';
import { cloneDeep } from 'lodash';

function ProductTypeWriter(props) {
    const [currentProduct, setCurrentProduct] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [dislayedProducts, setDisplayedProducts] = useState([])

    useEffect(() => {
        loadSequenceProducts()
        if(props.effects === false) {
            props.setLoading(true)
        }
    }, [props.products, props.isAnswerComplete, props.effects])

    // useEffect(() => {
    //     if(props.products) {
    //         if (currentIndex < props.products.length) {
    //         const timeout = setTimeout(() => {
    //             const displayedProducts = currentProduct;
    //             setCurrentProduct([...currentProduct, props.products[currentIndex]]);
    //             setCurrentIndex(prevIndex => prevIndex + 1);
    //             if(props.isAnswerComplete){
    //             // console.log("Displayed" + displayedProducts)
    //             // console.log("props.products" + props.products)
    //             }
    //             if(props.isAnswerComplete && displayedProducts.length >= props.products.length - 1){
    //                 console.log("end of product loopaaaaaaaaa")
    //                 props.setLoading(true)
    //             }
    //         }, currentIndex === 0 ? props.delay: 25000);
        
    //         return () => clearTimeout(timeout);
    //         } else {
    //         const displayedProducts = currentProduct;
    //         if(props.isAnswerComplete && displayedProducts.length >= props.products.length - 1){
    //             console.log("end of fallback product loop aaaaaaaaaattttttt")
    //             props.setLoading(true)
    //         }
    //         }
    //     }
    //     if(props.effects === false) {
    //         props.setLoading(true)
    //     }
    // }, [currentIndex, props.delay, props.products, props.isAnswerComplete, props.effects]);


    const handleValueChange = (childValue) => {
        props.onValueChange(childValue)
    };

    const loadSequenceProducts = () => {
        if(props.products) {
           // console.log(currentIndex,props.products,'props.products' );
            const productResponseStoppedIndex = props.products.findIndex((element) => element.responseStopped);
            if(productResponseStoppedIndex >= 0){
              const currentProducts = cloneDeep(currentProduct);
              currentProducts[productResponseStoppedIndex] = props.products[productResponseStoppedIndex];
              setCurrentProduct([...currentProducts]);
            }
            if (currentIndex < props.products.length) {
                const displayedProducts = currentProduct;
                setDisplayedProducts(displayedProducts)
                setCurrentProduct([...currentProduct, props.products[currentIndex]]);
                setCurrentIndex(currentIndex + 1);
                if(props.isAnswerComplete){
               // console.log("Displayed" + displayedProducts)
               // console.log("props.products" + props.products)
                }
                // console.log(displayedProducts.length, props.products.length, currentIndex, 'pp');
                // if(props.isAnswerComplete && displayedProducts.length >= props.products.length - 1){
                //     console.log("end of product loopaaaaaaaaa")
                //     props.setLoading(true)
                // }
            } 
            // else {
            // const displayedProducts = currentProduct;
                // console.log(displayedProducts.length, props.products.length, currentIndex, 'ppt');
                // if(props.isAnswerComplete && displayedProducts.length >= props.products.length - 1){
                //     console.log("end of fallback product loop aaaaaaaaaattttttt")
                //     props.setLoading(true)
                // }
            // }
        }
    }

    const handleCardFinish = () => {
        if (currentIndex < props.products.length) {
            loadSequenceProducts()
        } else {
            if(props.isAnswerComplete && dislayedProducts.length >= props.products.length - 1){
                props.setLoading(true)
            }
        }
    } 

    return (
        <div className={"product_typewriter"+props.index}>
            {currentProduct?.map((subItem, index) => {
                return(
                    <div>
                        <ProductCard convoId={props.convoId} product={subItem} index={index} conclusion={props.conclusion} isCategory={props.isCategory} onValueChange={handleValueChange} isStreaming={props.isStreaming} onCardFinish={handleCardFinish} effects={props.effects}   shouldLoadImage={!props.isStopResponseTriggered} 
                        isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}/>
                    </div>
            )})}
        </div>
    );
}

export default ProductTypeWriter;