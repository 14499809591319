import { useState } from "react";
import {Buffer} from 'buffer';

export default function Sfcc() {
    const [email, setEmail] = useState("");
    const [dwsid, setDwsid] = useState("");
    const [sfccApiResponse, setSfccApiResponse] = useState({});
    const [customerGroups, setCustomerGroups] = useState("");

    const userName = "ac5efe6d-ceaf-4b28-89ff-8258dcfd79ee"
    const password = "sYcon1W_BnNmtjajgLrgqi6bur9J1syZusSyhCeadvY"
    const sfccUrl = "https://7gxbizjm.api.commercecloud.salesforce.com/shopper/auth/v1/organizations/f_ecom_bbxf_dev/oauth2/session-bridge/token"
    const sfccGroupApiUrl = "https://dev-rldapi.ralphlauren.com/dw/shop/v20_10/customers/"

    const sfccApi = () => {
        const token = new Buffer.from(`${userName}:${password}`, 'utf-8').toString('base64');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic "+token);

        const urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");
        urlencoded.append("dwsid", dwsid);
        urlencoded.append("login_id", email);
        urlencoded.append("channel_id", "RalphLauren_US");

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
        };

        fetch(sfccUrl, requestOptions)
        .then((response) => response.text())
        .then((result) => {    
            setSfccApiResponse(result)        
            console.log(result)
        })
        .catch((error) => console.error(error));
    }

    const sfccGroupApi = () => {
        if(sfccApiResponse) {
            let sfccResponse = JSON.parse(sfccApiResponse)
            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+sfccResponse.access_token);
            // myHeaders.append("Cookie", "_pxhd=bf967d823c0fc5426626edd3ac9fd3d05f7c5b3e88ca2b9f25606742fa1f1b0e:b3d119c1-8ad2-11ef-b6ee-9950ccf6707f");

            const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
            };

            fetch(sfccGroupApiUrl+sfccResponse.customer_id, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result)
                setCustomerGroups(result.c_customergroup)
            })
            .catch((error) => console.error(error));
        }   
    }

    return (
        <div>
            <div>
                <div>
                    SFCC_CLIENT_ID - {userName}
                </div>
                <div>
                    SFCC_CLIENT_SECRET - {password}
                </div>
                <div>
                    SFCC_URL - {sfccUrl}
                </div>
            </div>
            <div>
                <div>
                    <label>Email</label>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div>
                    <label>DWSID</label>
                    <input type="text" value={dwsid} onChange={(e) => setDwsid(e.target.value)}/>
                </div>
                <button type="button" class="btn btn-primary" onClick={()=> sfccApi()}>SFCC API</button>
                {
                    Object.keys(sfccApiResponse).length>0 && 
                    <div>
                        <div>
                            <h1>SFCC API RESPONSE</h1>
                            <div>Access Token - {Object.keys(sfccApiResponse).length>0 && JSON.parse(sfccApiResponse).access_token}</div>
                            <div>CustomerId - {Object.keys(sfccApiResponse).length>0 && JSON.parse(sfccApiResponse).customer_id}</div>                
                        </div>
                        <div>
                            <div>
                                <div> SFCC_GROUP_URL - {sfccGroupApiUrl} </div>
                                <button type="button" class="btn btn-primary" onClick={()=> sfccGroupApi()}>Fetch Group</button>
                            </div>
                            <div>
                                { 
                                    customerGroups &&
                                        <div>
                                            <div>Customer Groups - {customerGroups}</div>                
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}