import { PublicClientApplication } from "@azure/msal-browser";
import { CONFIG_URL } from "../Settings/envConstants";

const msalConfig = {
    auth: {
        clientId: CONFIG_URL.clientId,
        authority: CONFIG_URL.tenantId,
        redirectUri: CONFIG_URL.redirectUri
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

export const loginRequest = { scopes: ['user.read'] }