import {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../Services/Redux/Reducers/commonSlice';
import {  likeordislike } from "../Services/ApiServices/chatServices";


function FeedbackModal({responseId, showModel,handleClose}) {

  // const [submitInput, setSubmitInput] = useState(false);
  const [inputBtnSelected, setInputBtnSelected] = useState('');
  const [additionalFeedbackText, setAdditionalFeedbackText] = useState('')
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  if(!showModel) return null;

  const feedbackValues = [" Quality of products & outfits", "Slow to respond", "Tone and copy length", "Response was inaccurate", "Hard to navigate" ,"Other"];

  const handleTextAreaChange = (event)=>{
      const fbText = event.target.value;
      setAdditionalFeedbackText(fbText);
      // setInputBtnSelected(fbText.trim().length > 0);
  }

  const handleLoader = () => {
    dispatch(loader(true))   
  };

  const handleStopResponse = () => {
    dispatch(loader(false))
  };

  
 const submitFeedback =()=>{
  // if(submitInput){
  //   handleClose();    
  // }
  handleClose();   
  setAdditionalFeedbackText("");
 }



  const handleInputFeedbackButton = (btnId)=>{   
    
    setInputBtnSelected(btnId)
    // setSubmitInput(true);        
  }


  // onclick of submit and suggested feedback
  const updateFeedback = () => {
      if(inputBtnSelected || additionalFeedbackText){
      let responseStatus = {
        responseId: responseId,
        feedBack: additionalFeedbackText ? inputBtnSelected + '-' + additionalFeedbackText : inputBtnSelected
      }
      // handleLoader();
      likeordislike(responseStatus,(response) => {
        // handleStopResponse();
      }, (err) => {
        setError(err.message);
        // handleStopResponse();
      });
    }
    submitFeedback();
  }

    

  return (
    
  <>{showModel &&
   
    <div className="modal d-flex fade show mainContainer" id="myModal" role="dialog" style={{display:"block"}} tabindex="-1" aria-hidden="true" >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className='modal-content_inner'>

            <div className="modal-header">
            <button name="closeButton" type="button" className="closebtn" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}><img src="./static/assets/Close_icon.svg" className="close_icon"/></button>                                         
            </div>

            <div className="modal-body">

              <div className='modal-body_title'>
                <span className='modal-body_headingText'>Provide Additional Feedback</span>
              </div>

            <div className='modal-body_suggestedFeedback'>

               {feedbackValues.map((value,index)=>(
              <div className='inputContainer'>
                <input key={index} className={`feedbackMsg ${inputBtnSelected === value ? 'selected' : "" }`}  type="button" value={value} 
                onClick={()=>handleInputFeedbackButton(value)}/>
              </div>
               ))}
            
              <textarea className='feedback_optional_box' placeholder='Provide additional details (optional)' value={additionalFeedbackText} 
              onChange={(e)=>handleTextAreaChange(e)}/>
            </div>


            {/* <div className="submitBtn">
              <button type="button" id="btnStyle" className={`btn btnSize ${inputBtnSelected ? 'activeBtnColor' : 'normalbtnClr'}`} disabled={!inputBtnSelected} onClick={updateFeedback}>Submit</button>
            </div> */}

            {additionalFeedbackText ? (
              <div className="submitBtn">
                <button name="submitButton" type="button" id="btnStyle" className={`btn btnSize activeBtnColor`} disabled={!additionalFeedbackText} onClick={updateFeedback}>Submit</button>
              </div>
             ):<div className="submitBtn">
              <button name="submitButton" type="button" id="btnStyle" className={`btn btnSize ${inputBtnSelected ? 'activeBtnColor' : 'normalbtnClr'}`} disabled={!inputBtnSelected} onClick={updateFeedback}>Submit</button>
              </div> 
            }

            </div>

                                
                </div>
      </div>
    </div>
  </div>
  }</>
  )
}

export default FeedbackModal