import { instance } from "../Settings/instance";
import { endpoints } from "../Settings/endpoints";
import { microsoftInstance } from "../Settings/microsoftInstance";
import { CONFIG_URL } from "../Settings/envConstants";

export const fetchMsgFromMicrosoft = async(params, route, success, error) => {
  await microsoftInstance.post(CONFIG_URL.microsoftInstance, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const fetchPreviousMsg = (params, success, error) => {
    instance.get(endpoints.CHAT_HISTORY_PREV+'?userId='+params.account+"&offset="+params.offset).then((res) => {
      success(res);
    }) .catch((err) => {
      error(err);
    });
}

export const fetchNextMsg = (params, success, error) => {
  instance.get(endpoints.CHAT_HISTORY_NEXT+'?userId='+params.account+"&offset="+params.offset).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const fetchMsg = (account, success, error) => {
    instance.get(endpoints.CHAT_HISTORY+'?userId='+account).then((res) => {
      success(res);
    }) .catch((err) => {
      error(err);
    });
}

export const addchat = (params, success, error) => {
    instance.post(endpoints.ADD_TO_CHAT, params).then((res) => {
      success(res);
    }) .catch((err) => {
      error(err);
    });
}

export const likeordislike = (params, success, error) => {
  instance.put(endpoints.LIKE_OR_DISLIKE, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const applicationfeedback = (params, success, error) => {
  instance.put(endpoints.APP_FEEDBACK, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const deleteChat = (params, success, error) => {
  instance.post(endpoints.DELETE_CHAT, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}



export const uniqueSessionHistory = (session, success, error) => {
  instance.get(endpoints.SESSION_HISTORY+'?sessionId='+session[1]+'&userId=' + session[0]).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}


export const uniqueGroupSessionHistory = (session, success, error) => {
  let params = {
    userId: session
  }
  instance.post(endpoints.UNIQUE_GROUP_SESSION_HISTORY, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const deleteChatData = (params, success, error) => {
  instance.post(endpoints.DELETE_CHAT_DATA, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const getAppFeedBack = (session, success, error) => {
  let params = {
    userId: session[0]
  }
  instance.post(endpoints.APP_LEVEL_FEEDBACK, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const saveAppLevelFeedBack = (params, success, error) => {
  instance.post(endpoints.SAVE_APP_LEVEL_FEEDBACK, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const updateShopClicked = async (params,success, error) => {
  instance.post(endpoints.UPDATE_SHOP_FLAG,params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}