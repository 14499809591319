import React, { useEffect, useState } from "react";
import { deleteChatData } from "../Services/ApiServices/chatServices";
import { useDispatch } from "react-redux";
import { apiError, conversationList } from "../Services/Redux/Reducers/chatSlice";
import { loader, stopStreaming } from "../Services/Redux/Reducers/commonSlice";
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie-consent';

function ChatOperation(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {data} = props
  const [show, setShow] = useState(false);
  const userId = Cookies?.get('ar_user_Id')?Cookies.get('ar_user_Id'):"";

  const handleClose = () => {
    setShow(false)
  }

  const deleteChat = () => {
      storeMessage(data);
      setShow(false)
  }

  const handleShow = () => setShow(true);

  const popUpClose = () => {
    setShow(false);
  }

  const storeMessage =async (data) => {
    // console.log(data,'datalid');
    const {sessionId} = data
    const params = {
        userId,
        sessionId
    }
    await deleteChatData(params,(response) => { // Chat History storing the messages      
    // console.log(response,'response');
    if(response.status === 200){
        navigate('/');
    }
    }, (err) => {
      dispatch(apiError(err.message));
      dispatch(loader(false));
    });
  }

  const newChat = () => {
    dispatch(stopStreaming(false))
    dispatch(conversationList([]))
    dispatch(loader(false));
    navigate('/chat');
  }

  // const handleDelete=()=>{
  //   setShow(false);
  // }
  return (
    <div className="addDelete ">
      <div className="d-flex justify-content-center align-items-end">
        <div
          className="d-flex justify-content-center align-items-end mb-5"
          style={{ gap: "65px"}}
        >
          <div className="d-flex flex-column  align-items-center">
            <button
              name="newChatButton"
              classname="btn btn-custom-blue  rounded-circle "
              style={{
                padding: "9px 10px",
                borderRadius: "22px",
                backgroundColor: "#041E3A",
                border: "none",
              }}
              onClick={newChat}
            >
              <img
                src="./static/assets/addChatIcon.svg"
                height="24px"
                width="24px"
                className="button-icon"
                style={{ border: "1px" }}
                alt="img"
              />
            </button>
            <span className="button-label">NEW CHAT</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              name="deleteChatButton"
              onClick={handleShow}
              classname="btn btn-custom-white rounded-circle "
              style={{
                padding: "9px 10px",
                borderRadius: "22px",
                backgroundColor: "#FFFFFF",
                border: "none",
              }}
            >
              <img
                src="./static/assets/deleteChatIcon.svg"
                className="button-icon"
                height="24px"
                width="24px"
                style={{ border: "1px" }}
                alt="img"
              />
            </button>
            <span className="button-label">DELETE CHAT</span>

            {show && (
              <div
                id="myModal" role="dialog"
                className="modal d-flex justify-content-center align-items-center"
                tabindex="-1"
                onClick={handleClose}
              >
                <div className="modal-dialog-main">
                  <div className="modal-content main-content justify-content-center">
                    <button
                      name="closeButton"
                      type="button"
                      className="modal-close-icon"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ><img src="./static/assets/Close_icon.svg" className="close_icon"/></button>

                    <div className="modal-body1 main-body-title">
                      <div className="modal_body_title1">
                        <p>Are you sure you want to delete the chat?</p>
                      </div>
                      <div className="modal_body_title2 mt-2">
                        <p>
                          By deleting the chat this message will no longer be available for access.
                        </p>
                      </div>
                    </div>
                    {/* <div className=" d-flex align-item-end main-footer"> */}
                    <div
                      className="d-flex justify-content-center "
                      style={{ width: "100%", padding: "0px 20px" }}
                    >
                      <button
                        name="deleteButton"
                        type="button"
                        className="btn btn-primary delete-btn me-2"
                        data-bs-dismiss="modal"
                        onClick={deleteChat}
                      >
                        DELETE CHAT
                      </button>

                      <button
                        name="cancelButton"
                        type="button"
                        className="btn btn-secondary cancel-btn"
                        onClick={popUpClose}
                      >
                        CANCEL
                      </button>
                    </div>
                    {/* </div>     */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChatOperation;
