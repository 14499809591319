import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MyCarousel({ handleCaroselAnimate,isBackFromArchive }) {
  const baseSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [settings, setSettings] = useState(baseSettings);
  useEffect(() => {
    const updateSettings = () => {
      const width = window.innerWidth;
      if (width > 577 && width < 991) {
        setSettings({
          ...baseSettings,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        });
      } else if (width < 576) {
        setSettings({
          ...baseSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
          centerPadding: "50px",
          // variableWidth: true,
          // afterChange: (currentIndex) => {
          //   // const slides = document.querySelectorAll(".slick-slide");
          //   // slides.forEach((slide) => {
          //   //   slide.style.transition = "-webkit-transform 500ms"; // Reset transition to avoid conflicts
          //   // });
          //   var element = document.querySelector(".slick-track");
          //   var currentTransform = getComputedStyle(element).transform;
          //   var match = currentTransform.match(
          //     /matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+)/
          //   );

          //   if (match) {
          //     var currentX = parseFloat(match[5]);
          //     var newX = currentX - 10;
          //     element.style.transform = "translate3d(" + newX + "px, 0px, 0px)";
          //     element.style.transition = '-webkit-transform 300ms'
          //   } else {
          //     console.log("Could not parse the current transform value.");
          //   }
          // },
        });
      } else {
        setSettings(baseSettings);
      }
    };
    window.addEventListener("resize", updateSettings);
    updateSettings();
    return () => {
      window.removeEventListener("resize", updateSettings);
    };
  }, []);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="next-arrow d-none d-lg-block" onClick={onClick}>
        <img
          className="pt-1 next-icon"
          src="/static/assets/NextArrow.svg"
          alt="next-arrow"
        />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="prev-arrow d-none d-lg-block" onClick={onClick}>
        <img
          className="pt-1 prev-icon"
          src="/static/assets/PrevArrow.svg"
          alt="prev-arrow"
        />
      </div>
    );
  }

  // var settings = {
  //   className: "slider variable-width",
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 0,
  //         centerMode: true,
  //         centerPadding: "0px",
  //         variableWidth: true,
  //         // afterChange: (currentSlide) => {
  //         //   console.log('After change - Current Slide:', currentSlide);
  //         //   const centeredSlide = document.querySelector('.slick-center');
  //         //   if (centeredSlide) {
  //         //     centeredSlide.classList.add('custom-centered-slide');
  //         //   }
  //         // }
  //       //   beforeChange: (current, next) => {
  //       //     const slides = document.querySelectorAll('.slick-slide');
  //       //     slides.forEach(slide => {
  //       //         slide.style.transition = '-webkit-transform 500ms'; // Apply custom transition
  //       //     });
  //       //   },
  //       //   afterChange: (currentIndex) => {
  //       //     const slides = document.querySelectorAll('.slick-slide');
  //       // slides.forEach(slide => {
  //       //     slide.style.transition = '-webkit-transform 500ms'; // Reset transition to avoid conflicts
  //       // });
  //       //     var element = document.querySelector('.slick-track');
  //       //     var currentTransform = getComputedStyle(element).transform;
  //       //     var match = currentTransform.match(/matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+)/);

  //       //     if (match) {
  //       //         var currentX = parseFloat(match[5]);
  //       //         var newX = currentX - 20;
  //       //         console.log(currentX, "currentX")
  //       //         console.log(newX, "newX")
  //       //         element.style.transform = 'translate3d(' + newX + 'px, 0px, 0px)';
  //       //         // element.style.transition = '-webkit-transform 200ms'
  //       //     } else {
  //       //         console.log("Could not parse the current transform value.");
  //       //     }
  //       // },
  //       },
  //     },
  //   ],
  // };
  const cards = [
    {
      id: 1,
      title: "Welcome",
      content: "I'm here to help you while you shop.",
    },
    {
      id: 2,
      title: "Outfit For Any Occasion",
      content: "What should I wear for a winter wedding in New York City? ",
    },
    {
      id: 3,
      title: "Product Discovery ",
      content: "Do you have any large Polo shirts in white? ",
    },
    {
      id: 4,
      title: "Timeless Gifting",
      content: "What gift should I get my significant other?",
    },
    {
      id: 5,
      title: "Brand History",
      content: "What's the orgin of the Polo Bear?",
    },
  ];

  return (
    <div className={`carousel-container slider-container container-fluid px-0 col-12 col-md-10 col-xl-9 ${!isBackFromArchive && 'animateSearchwithCarousel'} `}>
      <Slider {...settings}>
        {cards.map((card, i) => (
          <div style={{ width: "275px" }} >
            <div className="headerContainer">
              <span className="headerContainer_title me-1">{card?.title}</span>
              {i > 0 && (
                <button
                  name="tryItButton"
                  className="headerContainer_tryBtn btn--outline"
                  onClick={() => handleCaroselAnimate(card?.content)}
                >
                  Try it
                </button>
              )}
            </div>
            <div className="cardContent">
              <p className="cardContent-item">{card?.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MyCarousel;
