function Configuration() {
    console.log("deployed_prod");
    var configObject = {}
    configObject = {
        // apiUrl: "http://localhost:3000/",
        // apiUrl: "https://dev.askralph.ralphlauren.com/",
        // apiUrl: "https://uat.askralph.ralphlauren.com/",
        apiUrl: "https://askralph.ralphlauren.com/",
        feedBackCheckMonth : process.env.REACT_APP_SHOW_FEEDBACK_MODAL_IN_DAYS || 2,
        developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "shivakeerthi.karani@ralphlauren.com"]
    }
    return configObject;
}

export const CONFIG_URL = Configuration()