import { createSlice } from '@reduxjs/toolkit'
 
export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
      conversationList: [],
      storeChat: false,
      likeStatus: false,
      apiError: false,
      stopResponse: false,
      likeApiError: false,
      sessionHistory: [],
      groupSessionHistory : [],
      deleteChat : false,
      appLevelFeedback : [],
      showLikeDisLikeState : true,
      searchBoxVisible : true,
      userCheck : false
    },
    reducers: {
      conversationList: (state, action) => {
       state.conversationList = [...action.payload]
      },
      storeChat: (state, action) => {
        state.storeChat = action.payload
      },
      sessionHistory: (state, action) => {
        state.sessionHistory = action.payload
      },
      groupSessionHistory: (state, action) => {
        state.groupSessionHistory = action.payload
      },
      deleteChat: (state, action) => {
        state.deleteChat = action.payload
      },
      likeStatus: (state, action) => {
        state.likeStatus = action.payload
      },
      apiError: (state, action) => {
        state.apiError = action.payload
      },
      likeApiError: (state, action) => {
        state.likeApiError = action.payload
      },
      stopResponse: (state, action) => {
        state.stopResponse = action.payload
      },
      appLevelFeedback: (state, action) => {
        state.appLevelFeedback =  action.payload
      },
      showLikeDisLikeState: (state, action) => {
        state.showLikeDisLikeState = action.payload
      },
      searchBoxVisible: (state, action) => {
        state.searchBoxVisible = action.payload
      },
      userCheck: (state, action) => {
        state.userCheck = action.payload
      },
    },
  })
 
  // Action creators are generated for each case reducer function
  export const { conversationList, storeChat, likeStatus, apiError, likeApiError, stopResponse, sessionHistory,groupSessionHistory, deleteChat, appLevelFeedback, showLikeDisLikeState, searchBoxVisible,userCheck } = chatSlice.actions
 
  export default chatSlice.reducer
 