import React from 'react';
import Restricted from '../CommonComponents/Restricted';
import { Cookies } from 'react-cookie-consent';

const PrivateRoute = ({ children }) => {   
    if (Cookies?.get('ar_user_email') && (Cookies.get('ar_user_email') !== "" || Cookies.get('ar_user_email') !== null || Cookies.get('ar_user_email') !== undefined)) {
        return children;
    } else {
        return <Restricted/>;
    }
};

export default PrivateRoute;
