import { instance } from "../Settings/instance";
import { endpoints } from "../Settings/endpoints";


export const userSession = (params, success, error) => {
    instance.post(endpoints.USER_SESSION, params).then((res) => {
      success(res);
    }) .catch((err) => {
      error(err);
    });
}

export const addPermittedUsers = (params, success, error) => {
  instance.post(endpoints.ADD_PERMITTED_USERS, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const deletePermittedUsers = (params, success, error) => {
  instance.post(endpoints.DELETE_PERMITTED_USERS, params).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}

export const getPermittedUsers = async (success, error) => {
  instance.get(endpoints.GET_PERMITTED_USERS).then((res) => {
    success(res);
  }) .catch((err) => {
    error(err);
  });
}