import React,{useState,useRef,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import img1 from "./OnboardingImages/1-Welcome@2x.png";
import img2 from "./OnboardingImages/2-Outfits@2x.png";
import img3 from "./OnboardingImages/3-Discover@2x.png";
import img4 from "./OnboardingImages/4-Gifting@2x.png";
import img5 from "./OnboardingImages/5-Feedback@2x.png";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
 
const OnboardCarousel = ({ handleGetStartedClick, handleSkip }) => {
 
    const[centerSlidePercentage, setCenterSlidePercentage] = useState(95);
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isOnbaordAnimate, setIsOnbaordAnimate] = useState(false);
    // var inrWidth = window.innerWidth;
 
    const handleRedirect = () => {
      setIsOnbaordAnimate(true);
      handleGetStartedClick();
      handleSkip();
      navigate("/");
  };
 
      const slides = [
        {
          image: img1,
          headline: "Welcome To Ask Ralph",
          subtext:
            "You have been selected to trial Ask Ralph — your AI personal stylist. Currently in beta, Ask Ralph is here to assist your shopping journey.",
        },
        {
          image: img2,
          headline: "Explore Endless Outfits",
          subtext:
            "Tailored to your needs, Ask Ralph helps you find looks for any occasion and suggests outfits you'll love.",
        },
        {
          image: img3,
          headline: "Discover Iconic Pieces ",
          subtext:
            "Ask Ralph effortlessly pairs you with products through natural conversation, helping you perfect your style.",
        },
        {
          image: img4,
          headline: "Perfect Gifting Made Easy",
          subtext:
            "Ask Ralph can suggest the perfect present from Ralph Lauren for everyone on your list — even those hard-to-shop-for loved ones.",
        },
        {
          image: img5,
          headline: "Create Along With Us",
          subtext:
            "Help us improve your Ask Ralph experience by providing your feedback.",
        },
      ];
   
 
  const totalSlides = slides?.length;
 
  const progressPercentage = ((currentSlide + 1) / totalSlides) * 100;
  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);      
  };
       
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 375) {
        setCenterSlidePercentage(101);
      } else if (window.innerWidth === 425) {
        setCenterSlidePercentage(87);
      } else {
        setCenterSlidePercentage(95);
      }
    };
 
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the class based on initial window size
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
   
  return (
    <div className="carousel-container-onboard" >
        <div className="carousel-container" >
            <Carousel
                selectedItem={currentSlide}
                onChange={(index) => setCurrentSlide(index)}
                centerMode
                // centerSlidePercentage={100}                
                centerSlidePercentage={centerSlidePercentage}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                showArrows={false}
                swipeable>
 
                {slides.map((slide, index) => (
                    <div key={index} className="carousel-slide" style={index===0 ? {marginLeft:"1rem"} : index === 4 ? {marginRight:"1rem"} : {margin:"0"}}>
                        <img src={slide.image}  alt={`Slide ${index + 1}`} style={{width:"294px",height:"360px",borderRadius:"10px"}}/>
                        <div className="onboard_description" style={{position:"absolute",top:"238px",width:"254px",margin: "0px 20px"}} >
                          <div className="head_line">{slide.headline}</div>
                          <div className="sub_text">{slide.subtext}</div>
                        </div>
                    </div>
                ))}                      
            </Carousel>
        </div>
 
        <div className={`footer opacityCarouselItems ${isOnbaordAnimate ? 'opacityCarouselItemsReverse' : ''}`}>
            <div class="px-3 d-flex justify-content-center">
            <div className="progress mt-4">
                 <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
              </div>
            </div>
 
            <div className="d-flex mt-4 justify-content-center btn_margin">
                {progressPercentage === 100 ? (
                <button className="nextBtn" onClick={handleRedirect}><span style={{marginTop: "1px"}}>Get started</span>
                <span style={{marginLeft: '19px'}}>
                 <img
                  src="./static/assets/OnboardArrow.svg"
                  height="16px"
                  width="16px"
                  className="next-arrow"
                />
              </span>
                </button>
                ):<button className="nextBtn" onClick={handleNext} ><span style={{marginTop: "1px"}}>Next</span>
                 <span style={{marginLeft: '19px'}}>
                 <img
                  src="./static/assets/OnboardArrow.svg"
                  height="16px"
                  width="16px"
                  className="next-arrow"
                />
                </span>
                </button>
                }
            </div>
 
            <div className="text-center mt-3">
             <button name="skipTutorialButton" className="skip-text px-0" onClick={handleRedirect}>
               Skip Tutorial
             </button>
 
        </div>
        </div>
 
     </div>
  );
};
 
export default OnboardCarousel;