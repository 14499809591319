import React, { useState } from "react";
import { applicationfeedback } from "../Services/ApiServices/chatServices";

const DislikeModule = ({ isDislikeModalOpen, handleClose, updateRes }) => {
  const [selectedFeedbackBtn, setSelectedFeedbackBtn] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [error, setError] = useState("");

  const handleFeedbackBtn = (btnId) => {
    setSelectedFeedbackBtn(btnId);
  };

  const handleFeedbackText = (event) => {
    const additionalfeedback = event.target.value;
    setFeedbackText(additionalfeedback);
  };

  const submitFeedback = (feedBackData) => {
    updateRes(feedBackData.feedBack);
    handleClose();
    setFeedbackText("");
  };

  const updateAppFeedback = () => {
    let feedBackData = ''
    if (selectedFeedbackBtn || feedbackText) {
      let responseStatus = {
        feedBack: feedbackText
          ? selectedFeedbackBtn + "-" + feedbackText
          : selectedFeedbackBtn,
      };
      // applicationfeedback(
      //   responseStatus,
      //   (response) => {},
      //   (err) => {
      //     setError(err.message);
      //   }
      // );
      feedBackData = responseStatus
      console.log("responseStatus", responseStatus);
    }
    submitFeedback(feedBackData);
  };

  const customerFeedback = [
    "Style / Recommendation Accuracy",
    "Performance & Speed",
    "Ease of Use",
    "Tone & Voice",
    "Offensive or Unsafe Language",
    "Other Issues",
  ];

  return (
    <div>
      {isDislikeModalOpen && (
        <div className="modal d-flex" id="myModal" role="dialog">
          <div className="modal-content feedback-content">
            <button
              name="closeButton"
              type="button"
              className="close_btn align-self-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img src="./static/assets/Close_icon.svg" />
            </button>
            <div className="modal-content_inner">
              <div className="modal-body">
                <div className="headline pb-3">Thank You. Tell Us More</div>
                <div className="feedback-subtext pb-2">
                  Please help us improve by providing more <br/> details about your
                  experience
                </div>
                <div className="modal-body_suggestedFeedback justify-content-center">
                  {customerFeedback.map((value, index) => (
                    <div className="inputContainer">
                      <input
                        key={index}
                        className={`feedbackMsg ${
                          selectedFeedbackBtn === value ? "selected" : ""
                        }`}
                        type="button"
                        value={value}
                        onClick={() => handleFeedbackBtn(value)}
                      />
                    </div>
                  ))}
                  <div className="feedback-textarea">
                    <p className="char-count">{feedbackText.length}/ 250 </p>
                    <textarea
                      className="feedback_optional_box"
                      placeholder="Provide additional details (optional)"
                      value={feedbackText}
                      maxLength={250}
                      onChange={(e) => handleFeedbackText(e)}
                    />
                  </div>
                </div>

                {feedbackText ? (
                  <div className="submitBtn">
                    <button
                      name="submitButton"
                      type="button"
                      id="btnStyle"
                      className={`btnSize activeBtnColor submit_btn`}
                      disabled={!feedbackText}
                      onClick={updateAppFeedback}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="submitBtn">
                    <button
                      name="submitButton"
                      type="button"
                      id="btnStyle"
                      className={`btnSize submit_btn ${
                        selectedFeedbackBtn ? "activeBtnColor" : "normalbtnClr"
                      }`}
                      disabled={!selectedFeedbackBtn}
                      onClick={updateAppFeedback}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DislikeModule;
